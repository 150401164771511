let routes = [
  {
    path: "/",
    meta: { title: "Dashboard" },
    name: "home",
    component: () => import("../../components/business/pages/Index"),
  },
  {
    path: "/campaigns",
    meta: { title: "Campaigns" },
    name: "campaigns.index",
    component: () => import("../../components/pages/MyCampaignsPage"),
  },
  {
    path: "/campaign/create/:id",
    name: "campaign.create",
    component: () => import("../../components/pages/CreateCampaignPage"),
  },
  {
    path: "/campaign/:id",
    name: "campaign",
    component: () => import("../../components/pages/CampaignPage"),
  },
  {
    path: "/influencers",
    name: "influencers",
    meta: { title: "Influencers" },
    component: () => import("../../components/pages/InfluencersPage"),
    props: true,
  },
  {
    path: "/influencer/:id",
    name: "influencer",
    meta: { title: "Influencer" },
    component: () => import("../../components/common/pages/InfluencerView"),
  },
  {
    path: "/report",
    component: () => import("../../components/business/pages/report/Report"),
    children: [
      {
        path: "overall",
        name: "report.overall",
        meta: { title: "Summarized report" },
        component: () =>
          import("../../components/business/pages/report/Overall"),
      },
      {
        path: "daily",
        name: "report.daily",
        meta: { title: "Daily report" },
        component: () => import("../../components/business/pages/report/Daily"),
      },
      {
        path: "influencer",
        name: "report.influencer",
        meta: { title: "Influencer report" },
        component: () =>
          import("../../components/business/pages/report/Influencer"),
      },
      {
        path: "sales",
        name: "report.sales",
        meta: { title: "Sales" },
        component: () => import("../../components/business/pages/report/Sales"),
      },
      {
        path: "traffic",
        name: "report.traffic",
        meta: { title: "Traffic report" },
        component: () =>
          import("../../components/business/pages/report/TrafficReport"),
      },
      {
        path: "campaign",
        name: "report.campaign",
        component: () =>
          import("../../components/business/pages/report/CampaignReport"),
      },
      {
        path: "commission",
        name: "report.commission",
        meta: { title: "Commission report" },
        component: () =>
          import("../../components/business/pages/report/CommissionReport"),
      },
    ],
  },
  {
    path: "/profile",
    name: "profile",
    meta: { title: "Profile" },
    component: () => import("../../components/common/profile/Profile.vue"),
  },
  {
    path: "/invoices",
    name: "invoices",
    meta: { title: "Invoicing" },
    component: () => import("../../components/business/pages/Payment"),
  },
  {
    path: "/tracking",
    name: "tracking",
    meta: { title: "Tracking setup" },
    component: () => import("../../components/business/pages/Tracking"),
  },
  {
    path: "/influencer-promotion-codes",
    name: "influencer_promotion_codes",
    meta: { title: "" },
    component: () =>
      import("../../components/common/pages/InfluencerPromotionCodes"),
  },
  {
    path: "/logout",
    name: "logout",
    meta: { title: "Logout" },
    component: () => import("../../components/app/Logout"),
  },
  {
    path: "/posts/attach",
    name: "posts.attach",
    meta: { title: "Assign" },
    component: () => import("../../components/business/pages/PostsAttach"),
  },
  {
    path: "/posts/saved",
    name: "posts.detach",
    meta: { title: "Detach" },
    component: () => import("../../components/business/pages/PostsSaved"),
  },
  {
    path: "/posts/instagram-report",
    name: "posts.instagram_report",
    meta: { title: "Detach" },
    component: () =>
      import("../../components/business/pages/InstagramReportPage"),
  },
  {
    path: "/*",
    name: "404",
    meta: { title: "Page not found" },
    component: () => import("../../components/business/pages/404"),
  },
  // {
  //   path: "/*",
  //   name: "beta",
  //   meta: { title: "Beta" },
  //   component: () => import("../../components/business/pages/Beta"),
  // },
];

export default routes;
